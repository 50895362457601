import React from "react";
import { graphql } from "gatsby";
import substitute from "substituter";

import { concatClassNames as cn } from "@sys42/utils";

import { HeaderOverlap } from "../../components/HeaderOverlap";
import * as helperStyles from "../../pages/helpers.module.css";
import Layout from "../../layouts/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Headline } from "../../components/Headline";
import { ExampleList } from "../../components/ExampleList";
import { StrapiPageComponents } from "../../components/StrapiPageComponents";
import { ThreeColumnsDefault } from "../../components/ThreeColumnsDefault";

import imgCreateTask from "./createTask.min.png";
import imgOrderTesters from "./orderTesters.min.png";
import imgDiscoverInsights from "./discoverInsights.min.png";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HighlightedText, urlBookACall } from "@userbrain/website-ui";
import { ContentCentered } from "../../components/ContentCentered";
import { TwoColumns } from "../../components/TwoColumns";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialJamie,
  testimonialPascal,
  testimonialPaul,
  testimonialAaron,
} from "../../helpers";
import { Brands } from "../../components/Brands";

export default function ExampleCategory(props) {
  const { data } = props;

  const templateCategoryPage = data.strapiTemplateCategoryPage;

  const exampleCategory = templateCategoryPage.example_category;
  const pageComponents = templateCategoryPage.PageComponents;

  const title =
    templateCategoryPage.title || `User testing for ${exampleCategory.title}`;

  return (
    <Layout activeMenuItem={"templates"} title={title}>
      <article>
        <HeaderOverlap
          isDisplayCta
          overline={"User testing templates"}
          title={
            <>
              <span className={helperStyles.h1Serif}>{title}</span>
            </>
          }
          staticImage={(className) => (
            <GatsbyImage
              className={className}
              alt={`${exampleCategory.title} like environment`}
              image={
                templateCategoryPage.TeaserImage.localFile.childImageSharp
                  .gatsbyImageData
              }
            />
          )}
          buttonLabel={templateCategoryPage.c2aButtonLabel}
        />
        <div className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <Headline
            titleWidth={"16em"}
            title={substitute(templateCategoryPage.templatesTitle, {
              count: exampleCategory.examples.length,
            })}
          />

          <ExampleList
            context={`category_${exampleCategory.url}`}
            examples={exampleCategory.examples}
          />

          {pageComponents?.length > 0 && (
            <StrapiPageComponents
              pageComponents={pageComponents}
              className={helperStyles.spacingLg}
            />
          )}

          {templateCategoryPage.createTasksText && (
            <ThreeColumnsDefault
              className={helperStyles.spacingLg}
              title={templateCategoryPage.howToTestTitle}
              subtitle={templateCategoryPage.howToTestSubtitle}
              col1Image={imgCreateTask}
              col1Alt={"Userbrain interface showing how to create a task"}
              col1Heading={"Create tasks"}
              col1Text={templateCategoryPage.createTasksText}
              col2Image={imgOrderTesters}
              col2Alt={"Userbrain interface showing how to order testers"}
              col2Heading={"Order testers"}
              col2Text={
                "Filter the Userbrain testing community by demographics and order as many testers as you need. Within hours, you'll receive video recordings (screen + audio) from each tester."
              }
              col3Image={imgDiscoverInsights}
              col3Alt={"Userbrain interface showing how to discover insights"}
              col3Heading={"Discover insights"}
              col3Text={
                "Watch and analyze your completed user test videos in a flash thanks to helpful features such as AI Insights and Transcripts. Collaborate with your team, share notes, and provide test Reports to your stakeholders."
              }
            />
          )}

          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Ready?{" "}
                <HighlightedText cursor>Start testing now.</HighlightedText>
              </>
            }
          />

          {templateCategoryPage.categoryNaming1 && (
            <ContentCentered
              className={helperStyles.spacingLg}
              title={`How many testers do I need to test my ${templateCategoryPage.categoryNaming1}?`}
              titleWidth={"16em"}
              textContent={`When it comes to testing your ${templateCategoryPage.categoryNaming1}, we recommend testing with at least 5 testers per round, up to a maximum of 12. Remember, the user testing process should be lean, iterative, and repetitive. That way, you can build a solid understanding of how your users interact with your product over time and make any necessary tweaks as required.`}
              image={(className) => (
                <StaticImage
                  className={className}
                  src={"./how-many-testers-fs8.png"}
                />
              )}
            />
          )}

          {templateCategoryPage.categoryNaming1 && (
            <TwoColumns
              className={helperStyles.spacingLg}
              heading={"Need a hand?"}
              content={`If you're ready to take your ${templateCategoryPage.categoryNaming1} to the next level, book a one-to-one call with one of our user testing specialists. Whatever your budget and user testing experience level, we'll find the perfect Userbrain plan for you!`}
              linkHref={urlBookACall}
              linkText={"Schedule a call with us"}
              image={(className) => (
                <StaticImage
                  className={className}
                  src={"./need-a-hand-fs8.png"}
                />
              )}
            />
          )}

          <div className={helperStyles.container}>
            <Testimonials
              className={helperStyles.spacingLg}
              title={
                "Userbrain is trusted by UX & product teams around the world:"
              }
              titleWidth={"17em"}
              testimonials={[
                testimonialPascal,
                testimonialPaul,
                testimonialJamie,
                testimonialAaron,
              ]}
            />
            <Brands className={helperStyles.spacingLg} hideDescription={true} />
          </div>
        </div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query templateCategoryPage($strapiId: String!) {
    strapiTemplateCategoryPage(id: { eq: $strapiId }) {
      id
      title
      templatesTitle
      c2aButtonLabel
      categoryNaming1
      createTasksText
      howToTestTitle
      howToTestSubtitle
      TeaserImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1680
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      example_category {
        title
        url
        examples {
          id
          url
          title
          type
          device
          companyName
          cardTitleWhite
          taskTypes {
            title
            url
          }
          categories {
            title
            url
          }
          cardColor
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
      ...PageComponentsFragment
    }
  }
`;
