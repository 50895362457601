import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";
import { ButtonLink, Headline2Sans } from "@userbrain/website-ui";
import * as styles from "./styles.module.css";
import { Image } from "../Image";

export function ContentCentered(props) {
  const {
    className,
    image: imageData, // Todo: Document this, this can be removed when imageSrc and imageAlt are removed/refactored
    imageSrc,
    imageAlt,
    title,
    titleWidth,
    textContent,
    textContentAlignLeft,
    linkTo,
    linkHref,
    linkText,
    ...containerProps
  } = props;

  let image = null;
  if (imageData) {
    image = imageData;
  } else if (imageSrc) {
    image = { src: imageSrc, alt: imageAlt };
  }

  return (
    <div className={cn(className, styles.contentCentered)} {...containerProps}>
      {title && (
        <Headline2Sans
          className={styles.title}
          style={{ maxWidth: titleWidth }}
        >
          {title}
        </Headline2Sans>
      )}
      {image && <Image image={image} className={styles.image} />}
      <div
        className={cn(
          styles.textContent,
          textContentAlignLeft && styles.textContent_alignLeft
        )}
      >
        {textContent}
      </div>
      {linkText && (
        <ButtonLink
          className={styles.link}
          customTag={linkTo ? Link : "a"}
          to={linkTo}
          href={linkHref}
        >
          {linkText}
        </ButtonLink>
      )}
    </div>
  );
}
