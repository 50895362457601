import React from "react";
import { graphql } from "gatsby";
import { concatClassNames as cn } from "@sys42/utils";
import { ContentCentered } from "../ContentCentered";
import { ListCentered } from "../ListCentered";
import { Headline } from "../Headline";

import * as helperStyles from "../../pages/helpers.module.css";

export function StrapiPageComponents(props) {
  const { className, children, pageComponents, ...restProps } = props;

  return (
    <div className={cn(className)} {...restProps}>
      {pageComponents.map((component, index) => {
        switch (component.strapi_component) {
          case "page-components.content-centered":
            return (
              <ContentCentered
                key={component.id}
                className={index > 0 && helperStyles.spacingLg}
                title={component.Title}
                titleWidth={`${component.titleWidth}em`}
                textContent={component.content?.data.content}
              />
            );
          case "page-components.list-centered-with-headline":
            const listCenteredItems = component.listCenteredItems.map(
              (item) => {
                return {
                  title: item.title,
                  text: item.content?.data.content,
                };
              }
            );

            return (
              <div className={helperStyles.spacingLg}>
                <Headline
                  className={index > 0 && helperStyles.spacingLg}
                  title={component.title}
                  subtitle={component.subtitle}
                  subtitleWidth="42em"
                />
                <ListCentered
                  className={cn(
                    helperStyles.spacingMd,
                    helperStyles.marginAuto
                  )}
                  color="var(--color-secondary-2)"
                  items={listCenteredItems}
                  type="number"
                />
              </div>
            );
          default:
            return <div>Unknown component: {component.strapi_component}</div>;
        }
      })}
    </div>
  );
}

export const query = graphql`
  fragment PageComponentsFragment on STRAPI_TEMPLATE_CATEGORY_PAGE {
    PageComponents {
      ... on STRAPI__COMPONENT_PAGE_COMPONENTS_CONTENT_CENTERED {
        strapi_component
        id
        Title
        titleWidth
        content {
          data {
            content
          }
        }
      }
      ... on STRAPI__COMPONENT_PAGE_COMPONENTS_LIST_CENTERED_WITH_HEADLINE {
        strapi_component
        id
        title
        subtitle
        listCenteredItems {
          title
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`;
