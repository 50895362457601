import React from "react";
import { accessibleOnClick, concatClassNames as cn } from "@sys42/utils";
import * as styles from "./styles.module.css";
import { AutoLink } from "../helpers/AutoLink";
import { navigate } from "gatsby-plugin-intl";
import { Button } from "@userbrain/website-ui";
import { ButtonPrimary } from "@userbrain/website-ui";

export function ListCentered(props) {
  const {
    className,
    title,
    titleWidth,
    items,
    color,
    type,
    withLearnMoreButton,
    learnMoreButtonIsPrimary,
    ...containerProps
  } = props;

  function handleClickItem(e, item) {
    if (item.linkHref) {
      window.location.href = item.linkHref;
    } else if (item.linkTo) {
      navigate(item.linkTo);
    }
  }

  return (
    <div
      className={cn(
        className,
        styles.listCentered,
        type === "number" && styles.listCentered_number
      )}
      {...containerProps}
    >
      {items.map((item, index) => (
        <div
          className={cn(
            styles.item,
            (item.linkTo || item.linkHref) && styles.item_linked,
            withLearnMoreButton && styles.item_withLearnMoreButton
          )}
          {...((item.linkTo || item.linkHref) &&
            accessibleOnClick((e) => handleClickItem(e, item)))}
          style={{ backgroundColor: color }}
          key={index}
        >
          <div>
            {item.title && (
              <h2>
                {item.linkTo || item.linkHref ? (
                  <AutoLink
                    className={styles.titleLink}
                    to={item.linkTo}
                    href={item.linkHref}
                    title={item.linkTitle}
                  >
                    {item.title}
                  </AutoLink>
                ) : (
                  item.title
                )}
              </h2>
            )}
            {item.text && <div className={styles.text}>{item.text}</div>}
          </div>

          {withLearnMoreButton && learnMoreButtonIsPrimary === true && (
            <ButtonPrimary
              customTag={AutoLink}
              to={item.linkTo}
              href={item.linkHref}
            >
              Learn more
            </ButtonPrimary>
          )}
          {withLearnMoreButton && learnMoreButtonIsPrimary !== true && (
            <Button customTag={AutoLink} to={item.linkTo} href={item.linkHref}>
              Learn more
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}
