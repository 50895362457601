import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { ExampleCard } from "../ExampleCard";
import * as styles from "./styles.module.css";

export function ExampleList(props) {
  const { className, examples, context, ...containerProps } = props;

  return (
    <div className={cn(className, styles.exampleList)} {...containerProps}>
      {examples.map((exampleItem, index) => {
        let linkTo = `/user-testing-templates/${exampleItem.url}`;
        if (context) {
          linkTo += `?context=${context}`;
        }
        return (
          <ExampleCard
            key={index}
            title={exampleItem.title}
            brandName={exampleItem.companyName}
            brandLogo={exampleItem.logo.localFile.publicURL}
            brandColor={`#${exampleItem.cardColor}`}
            whiteText={exampleItem.cardTitleWhite}
            className={styles.exampleCard}
            linkTo={linkTo}
          />
        );
      })}
    </div>
  );
}
