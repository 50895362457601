import React, { useState } from "react";
import { navigate } from "gatsby-plugin-intl";
import { random } from "lodash";
import { concatClassNames as cn, accessibleOnClick } from "@sys42/utils";
import * as styles from "./styles.module.css";
import { AutoLink } from "../helpers/AutoLink";

export function ExampleCard(props) {
  const {
    brandColor,
    brandLogo,
    brandName,
    className,
    lineStyle,
    title,
    whiteText,
    linkTo,
    ...containerProps
  } = props;

  const [randomLineStyle] = useState(random(1, 4));

  function handleClick() {
    navigate(linkTo);
  }

  return (
    <div
      className={cn(
        className,
        styles.exampleCard,
        whiteText && styles.exampleCard_whiteText
      )}
      style={{ backgroundColor: brandColor }}
      data-line-style={lineStyle || randomLineStyle}
      {...accessibleOnClick(
        handleClick,
        -1 /* Ignore tab index because title is linked as well */
      )}
      {...containerProps}
    >
      <AutoLink className={styles.title} to={linkTo}>
        {title}
      </AutoLink>
      <div className={styles.brandAndCaret}>
        <div>
          featuring
          <br />
          {brandName}
        </div>
        <div className={styles.caret} />
      </div>

      <div className={styles.hoverOpenIndicator}>Open this template</div>
    </div>
  );
}
